import React, { useState } from "react";
import { Header } from '../../components/Header/index.js';
import { GiCheckMark } from "react-icons/gi";


export const Planos = () => {
    return (
        <>
            <Header menuAtivo='Planos' />
            <div className="container-fluid  capa-site d-flex align-items-center" style={{ height: '50vh' }}>
        <div className="row pl-2">
          <div className="col-12 col-lg-12 col-sm-12 col-md-12">
            <h1 className='text-white text-left montserrat font-sobre no-bold wi-100'>O mais completo sistema <b>ERP</b> por um preço único!</h1>
          </div>
        </div>
      </div>
            <div className="container-fluid d-flex flex-column align-itens-start mt-7" style={{marginRight: '5%'}}>
                <div className="row mb-4">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 pl-2 pl-sm-1">
                        <h3 className="text-black yama-titles text-center">
                            A melhor opção para o seu negócio!
                        </h3>
                    </div>
                </div>
                <div className="row mb-4 align-items-start justify-content-center flex-wrap" style={{marginRight: '5%'}}>
                    <div className="col-12 col-sm-7 col-md-5 col-lg-4 pl-1 pl-sm-1 mt-4">
                        <div className="pricing-table">
                            <div className="pricing-card bg-gray">
                                <h3 className="pricing-card-header">Padrão</h3>
                                <div className="price"><sup>R$</sup>100<span>/BRL</span></div>
                                <ul>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <div className="img-card-pl" style={{ width: '50px', height: '50px' }}>
                                                <img className='fadeIn' src={require('../../img/gestão-de-vendas.png')} alt="biometria" />
                                            </div>

                                            &nbsp;
                                            <span>Gestão Financeira</span>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="d-flex align-items-center">
                                            <div className="img-card-pl" style={{ width: '50px', height: '50px' }}>
                                                <img className='fadeIn' src={require('../../img/gestão-de-estoque.png')} alt="biometria" />
                                            </div>

                                            &nbsp;
                                            <span>Controle de Estoque</span>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="d-flex align-items-center">
                                            <div className="img-card-pl" style={{ width: '50px', height: '50px' }}>
                                                <img className='fadeIn' src={require('../../img/gestão-de-patrimônio.png')} alt="biometria" />
                                            </div>

                                            &nbsp;
                                            <span>Gestão de Patrimônio</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center">
                                            <div className="img-card-pl" style={{ width: '50px', height: '50px' }}>
                                                <img className='fadeIn' src={require('../../img/gestão-de-compras.png')} alt="biometria" />
                                            </div>

                                            &nbsp;
                                            <span>Gestão de Compras</span>
                                        </div>
                                    </li>

                                </ul>
                                <a href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="order-btn">Adquirir</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-7 pl-2 pl-sm-1 mt-4">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead align="center"   style={{ fontSize: '14px', borderBottom: '3px solid #EEEE' }}>
                                    <tr>
                                        <th scope="col" >Funcionalidades</th>
                                        <th scope="col" >YamaCorp</th>
                                        <th scope="col" >Concorrente 1</th>
                                        <th scope="col" >Concorrente 2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td align="left" scope="row">Gameficação</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">CRM</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Controle de Ponto</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Intranet</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Frente de Loja Completo</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Integração com Sistemas de Franquias</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Conciliação Bancária e Cartão</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Controle de Compras</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Gestão Multilojas</td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/delete.png')} alt="" style={{ width: '32px' }} /></td>
                                        <td align="center"><img src={require('../../img/icons/check.png')} alt="" style={{ width: '32px' }} /></td>
                                    </tr>
                                    <tr>
                                        <td align="left" scope="row">Valor/Mês</td>
                                        <td align="center"><span className="fs-18 fw-bold">R$ 100,00</span></td>
                                        <td align="center"><span className="fs-14">R$ 250,00</span></td>
                                        <td align="center"><span className="fs-14">R$ 380,00</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
               
            </div>
            
            <div className="container-fluid bg-gray">
            <div className="row">
                    <div className="col-12 col-md-10 col-sm-10 col-lg-10 pl-2 pl-sm-1 pt-5">
                        <h3 className="text-uppercase">
                            Perguntas Frequentes:
                        </h3>
                    </div>
                    <div class="row " style={{marginLeft: '-20px'}}>
                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 pl-2 pl-sm-1">
                        <div className="bar-blue"></div>
                    </div>
                    </div>
                </div>
                <div className="row col-12 col-md-10 col-sm-10 col-lg-10 pl-2 pl-sm-1 float-none"  >
                    <div className="">
                        <div className="row align-items-center">
                            {/* <div className="col-12 col-md-4 col-sm-4 col-lg-4 pr-2 pr-sm-1">
                                <div className="faq-title mb-4 fw-bold fs-15 mt-3">
                                    Como faço para adquirir o meu sistema ERP?
                                </div>
                                <div className="mt faq-content  text-justify">
                                    Entre em contato com a gente por algum dos nossos canais de atendimento,
                                    ou deixe seus dados no Yama Fone que entraremos em contato!
                                </div>
                                <div className="mt">
                                    <a href="https://api.whatsapp.com/send?l=pt_BR&phone=5591985848269&text=Olá, tenho interesse no Yama Corp." target="_blank" className="pt-4">Clique aqui para saber mais</a>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-4 col-sm-4 col-lg-4 pr-2 pr-sm-1">
                                <div className="faq-title mb-4 fw-bold fs-15 mt-3">
                                    Consigo adequar o sitema para as minhas lojas franqueadas?
                                </div>
                                <div className="faq-content  text-justify">
                                    O Yama Corp conta com um sistema de migração, podendo trazer seus dados e relatórios
                                    para seu mais novo e completo sistema de gestão. <br />
                                </div>
                                <div className="mt-mt">
                                    <a href="https://api.whatsapp.com/send?l=pt_BR&phone=5591985848269&text=Olá, tenho interesse no Yama Corp." target="_blank" className="pt-4">Clique aqui para saber mais</a>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-4 col-sm-4 col-lg-4 pr-2 pr-sm-1" >
                                <div className="faq-title mb-4 fw-bold fs-15 mt-3">
                                    É preciso ter uma Empresa (cnpj) para poder usufruir do Yama Corp?
                                </div>
                                <div className="faq-content  text-justify">
                                    Não, se você é vendedor informal também vai poder
                                    usufruir do sistema Yama Corp,  basta se cadastrar e parametrizar o sistema de acordo com a sua modalidade de uso.

                                </div>
                                <div className="mt">
                                    <a href="https://api.whatsapp.com/send?l=pt_BR&phone=5591985848269&text=Olá, tenho interesse no Yama Corp." target="_blank" className="pt-4">Clique aqui para saber mais</a>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-4 col-sm-4 col-lg-4 pr-2 pr-sm-1">
                                <div className="faq-title mb-4 fw-bold fs-15 mt-4">
                                    Como faço para adquirir o meu sistema ERP?
                                </div>
                                <div className="faq-content fs-13">
                                    Nam tellus sem, porta luctus tortor vitae, cursus elementum nisi.
                                    Curabitur dictum lorem eget pharetra egestas.
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-4 col-sm-4 col-lg-4 pr-2 pr-sm-1">
                                <div className="faq-title mb-4 fw-bold fs-15 mt-4">
                                    Consigo adequar ao minhas lojas franqueadas?
                                </div>
                                <div className="faq-content fs-13">
                                    Cras fermentum odio a elit congue convallis.
                                    In mattis leo et libero pellentesque, sit amet aliquam lectus finibus.
                                    In dictum urna urna, ut pulvinar nibh lobortis vel. Donec in aliquam odio.
                                    Integer quis lacus sapien.
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-4 col-sm-4 col-lg-4 pr-2 pr-sm-1">
                                <div className="faq-title mb-4 fw-bold fs-15 mt-4">
                                    É preciso ter uma empresa para poder usufruir do YamaCorp?
                                </div>
                                <div className="faq-content fs-13">
                                    Nam tellus sem, porta luctus tortor vitae, cursus elementum nisi.
                                </div>
                            </div> */}

                       
                    </div></div> </div></div>

                    <div class="row " style={{marginLeft: '15px'}}>
                    <div className="col-md-4">
                    <div className="row g-0  overflow-hidden flex-md-row mb-4  h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          <strong className="d-inline-block mb-2 text-success"> Como faço para adquirir o meu sistema ERP?</strong>
          {/* <h3 className="mb-0"> Como faço para adquirir o meu sistema ERP?</h3> */}
          {/* <div className="mb-1 text-muted">Nov 11</div> */}
          <p className="mb-auto">Entre em contato com a gente por algum dos nossos canais de atendimento,
                                    ou deixe seus dados no Yama Fone que entraremos em contato!</p>
                                    <a href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="pt-4">Clique aqui para saber mais</a>
        </div>        
      </div>
    </div>

    <div className="col-md-4" >
    <div className="row g-0  overflow-hidden flex-md-row mb-4  h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          <strong className="d-inline-block mb-2 text-success"> Eu consigo adpatar o sitema para as minhas lojas franqueadas?</strong>
          {/* <h3 className="mb-0">Post title</h3>
          <div className="mb-1 text-muted">Nov 11</div> */}
          <p className="mb-auto"> O Yama Corp conta com um sistema de migração, podendo trazer seus dados e relatórios
                                    para seu mais novo e completo sistema de gestão.</p>
          <a href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="pt-4">Clique aqui para saber mais</a>
        </div>       
      </div>
    </div>

    <div className="col-md-4">
    <div className="row g-0  overflow-hidden flex-md-row mb-4  h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          <strong className="d-inline-block mb-2 text-success"> É preciso ter uma Empresa (cnpj) para poder usufruir do Yama Corp?</strong>
          {/* <h3 className="mb-0">Post title</h3>
          <div className="mb-1 text-muted">Nov 11</div> */}
          <p className="mb-auto">  Não, se você é vendedor informal também vai poder
                                    usufruir do sistema Yama Corp,  basta se cadastrar e parametrizar o sistema de acordo com a sua modalidade de uso.</p>
          <a href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="pt-4">Clique aqui para saber mais</a>
        </div>
        </div>
    </div></div>
     
               
            
            <div className="container-fluid mt-4"  >
                <div className="row">
                    <div className="col-12 col-sm-10 col-lg-10 col-sm-10 pl-2 pl-sm-1 d-flex align-items-center flex-wrap sec-mais-duvidas">
                        <div className="col-12 col-sm-8 col-lg-6 col-md-6 ">
                            <div className="planos-img">
                                <img src={require('../../img/banner-faq.png')} className="planos-img--pos" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 col-lg-6 col-md-6 pl-2 pl-sm-1">
                            <div className="mt-">
                                <h2 className="text-justify shadow-lg p-3 mb-5 bg-white rounded" >Ainda com dúvidas sobre como o Yama Corp pode ajudar alavancar seu negócio?</h2>
                                <br />
                                <div className="mt" style={{marginTop: '-40px'}}>
                                    <a style={{textDecoration: 'none', padding: '0px px 0px 50px'}}
                                     href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="pt-4">
                                        Fale com a gente!!📲</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-10 col-sm-10 col-lg-10 pl-2 pl-sm-1">
                        <div className="bar-gray"></div>
                    </div>
                </div>
                <div className="row mt-4 mb-5" >
                    <div className="col-12 d-flex" style={{ justifyContent: 'space-evenly' }}>
                    <a target={"_blank"} href={process.env.REACT_APP_WHATSAPP}  className="text-muted" style={{textDecoration: 'none'}}>
                        <div className="whatsapp">
                            <div className="img">
                                <img src={require('../../img/icons/phone-call.png')} style={{ maxWidth: '40px', marginLeft: '20px' }} />
                            </div>
                            <div className="link mt-2">
                                Whatsapp
                            </div>
                        </div>
                        </a>


                        <div className="ml-4 phone d-flex flex-column align-items-center">
                        <a href="tel:+559132151100" style={{textDecoration: 'none'}}>                       
                            <div className="img">
                            
                                <img src={require('../../img/icons/call.png')} style={{ maxWidth: '40px', marginLeft: '50px' }} />
                                
                            </div>
                            <div className="link mt-1">
                              <span className="text-muted">+55 (91) 3215-1100</span>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// export default Planos