import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home/index.js';
import { Contatos } from './pages/Contatos/index.js';
import { Header } from './components/Header/index.js';
import Menu from './components/Menu';
import Footer from './components/Footer';
import { ButtonEmail } from './components/ButtonEmail/index.js'
// import SwiperTeste from './components/CarrouselYama';
// import CarrouseCards from './components/CarrouseCards';
import { Planos } from './pages/Planos/index.js'
import { Funcionalidades } from './pages/Funcionalidades/index.js'
 import { Cadastro } from './components/Cadastro/index.js';
import { Link } from './pages/Link/index.js';
// import { Login } from './pages/Link/index.js';
const Rotas = () => {
    return (
        <BrowserRouter>
            {/* <Header></Header> */}
            <ButtonEmail />
            <Routes>
                <Route path='menu' element={<Menu />}></Route>
                <Route path='header' element={<Header />}></Route>
                <Route path='/' element={<Home />}></Route>
                <Route path='contatos' element={<Contatos />}></Route>
                <Route path='planos' element={<Planos />}></Route>
                <Route path='funcionalidades' element={<Funcionalidades />}></Route>
                <Route path='button' element={<ButtonEmail />}></Route>
                <Route path='cadastro' element={<Cadastro/>}></Route>
                 <Route path='link' element={<Link />}></Route> 
                {/* <Route path='login' component={() => { 
     window.location.href = 'https://www.google.com.br'; 
     return null;
}}/> */}
{/* <Route
path='login'  component={ Link }  loc="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"
>
  </Route> */}


  {/* <Route path='/login' component={() => { 
     window.location.href = 'https://example.com/1234'; 
     return null;
}}/> */}
            </Routes>
            <Footer></Footer>
        </BrowserRouter>
    )
}


export default Rotas;