import Rotas from './routes';
import 'bootstrap/dist/css/bootstrap.min.css'
import { isIntersectionTypeNode } from 'typescript';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Rotas></Rotas>
  );
}
 


export default App;
