import './style.css';
import React, { useState } from "react";
export function Cadastro  ()   {    
   // require("dotenv").config();
    // console.log(process.env.REACT_APP_API_INSOMNIA);

const [Iempresa, setEmpresa] = useState("");
const [Inome, setNome] = useState("");
const [Iemail, setEmail] = useState("");
const [Icontato, setContato] = useState("");
//const [Imensagem , setMensagem]  = useState("CI");
const  Imensagem = 'Contato' ;

    const maskPhone = (value) => {
        return value
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "($1) $2")
          .replace(/(\d{5})(\d)/, "$1-$2")
          .replace(/(-\d{4})(\d+?)$/, "$1");
      };
    // <!DOCTYPE html>
    // <html lang="en">
    // <head>
    //     <meta charset="UTF-8">
    //     <meta http-equiv="X-UA-Compatible" content="IE=edge">
    //     <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //     <link rel="stylesheet" href="style.css">
    //     <title>Cadastro Yama Corp</title>
    // </head>
    function enviar() {       
       
       
       
        if(Icontato.length <= 14  )
    {           
      console.log('Telefone no formato: (99) 99999-9999 ');
      alert('Telefone no formato: (99) 99999-9999 ');
      Icontato.addEventListener('invalid', function () {
        Icontato.setCustomValidity('(00) 00000-0000');        
             }, false);
            }else if (Iempresa === "") {        
      
                alert('Campo empresa é obrigatório '); 
            } else if (Inome === "") {        
      
                alert('Campo nome é obrigatório ');
                         
           } else if (Iemail === "") {        
        // console.log('empresa=' + document.getElementById('Inputempresa').value);
        // console.log('nome=' + document.getElementById('Inputnome').value);
        // console.log('email=' + document.getElementById('Inputemail').value);
        // console.log('contato=' + document.getElementById('Inputcontato').value);
        // console.log('mensagem=' + document.getElementById('Inputmensagem').value);
    //     var Iempresa = document.getElementById('Inputempresa').value;
    //     var Inome = document.getElementById('Inputnome').value;
    //     var Iemail = document.getElementById('Inputemail').value;
       // var Icontato = document.getElementById('Inputcontato').value;
    //     var Imensagem = document.getElementById('Inputmensagem').value;
    alert('Campo email é obrigatório ');
     }else{
    const data = { empresa: Iempresa, nome: Inome, email: Iemail, contato: Icontato, mensagem: Imensagem};
        fetch(process.env.REACT_APP_CONTATO_CLIENTE + "contato/clientes" , {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
        });           
    }}


   return (
        <>  
{/* <div>
      <small>Endereço da aplicação <b>{process.env.REACT_APP_MSG}</b> mode.</small>
    </div> */}
            <div className="container">
           
 
 
                <div className="py-5 text-center" style={{ marginTop: '-5%' }}>
                    <img src={require('../../img/favicon.png')} alt="Yama Corp" width="130" height="100" />
                    <h1>Yama Corp</h1>
                    <p className="lead"><h2>Faça seu Cadastro!!!<br />Entre para o melhor sistema ERP.</h2></p>
                </div>
                <div className="row">
                    <form className="needs-validation" method="post" action="" id="formulario" novalidate>
                        <div className="col-md-6 order-md-1 " style={{ margin: '0 15% 0 15%', marginTop: '-5%', width: 'auto' }}>
                           <form>
                           <div className="row">
                                <div class="mb-3">
                                    <label for="Inputempresa">Empresa</label>
                                    <input type="text" className="form-control" id="Inputempresa" name="empresa" placeholder="Digite o CNPJ ou CPF..." 
                                    value={Iempresa}
                                    onChange={(e) => setEmpresa(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row"   >
                                <div class="mb-3">
                                    <label for="Inputnome">Nome</label>
                                    <input type="text" className="form-control" id="Inputnome" name="Inputnome" placeholder="DIgite teu nome...." required 
                                    value={Inome}
                                    onChange={(e) => setNome(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div class="mb-3">
                                    <label for="Inputemail">Email</label>
                                    <input type="text" className="form-control" id="Inputemail" name="Inputemail" placeholder="Digite o email..." required
                                    value={Iemail}
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div class="mb-3">
                                    <label for="Inputcontato">Contato</label>
                                    <input type="text" className="form-control"
                                     id="Inputcontato" 
                                     name="Inputcontato"  
                                     required 
                                    value={Icontato}
                                    onChange={(e) => setContato(maskPhone(e.target.value))}
                                    placeholder="Digite o telefone - formato: (00) 00000-0000" 
                                    pattern="\(\d{2}\) \d{5}-\d{4}"/>
                               
                                </div>
                            </div>
                           
                          {/*   <div className="row">
                                <div class="mb-3">
                                    <label for="Inputmensagem">Mensagem</label> 
                                    <input type="hidden" className="form-control" id="Inputmensagem" placeholder="Digite o cidade..." required
                                    onChange={(e) => setMensagem(e.target.value)}
                                    value={Imensagem}
                                    />
                                </div>
                            </div>*/}
                            <hr className="mb-4" />
                            <button className="btn btn-primary btn-lg btn-block" onClick={enviar} id="enviar" name="enviar" type="submit">Enviar</button>
                            </form> 
                        </div>
                    </form>
               </div>
           </div>
        </>

    )


}

export default Cadastro;