import {
  SiDiscord,
  SiWhatsapp,
  SiFacebook,
  SiTwitter,
  SiInstagram,
  SiLinkedin,
  SiAcademia,
  SiExpressvpn,
  Si1Password,
  SiAdidas,
  SiAdobe,
  SiJavascript,
  SiNike,
  SiBadoo,
  SiTinder,
  SiLenovo,
  SiDell,
  SiPhp,
  SiEpson,
  SiBlueprint,
  SiIntel,
  SiUndertale,
  SiSignal,
  SiTelegram,
} from "react-icons/si";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  var today = new Date();

  return (
    <div className="footer" style={{ marginTop: "" }}>
      <div className="container-fluid footer-yama--endereco ">
        <div className="row d-flex justify-content-center ">
          <div class="col-6 col-md-2 mb-3" style={{ marginLeft: "2.5%" }}>
            <ul className="list-unstyled  flex-column">
              <li className="text-white fw-bold fs-14 footer-item-lista">Suporte Yama Corp</li>
              {/* <li className="text-white fw-bold fs-14 "></li> */}
              <li className="text-white fs-13 footer-item-lista">
                <a href="/">Página Inicial</a>
              </li>
              <li className="text-white fs-13 footer-item-lista">
                <a href="contatos">Nossos Recursos</a>
              </li>
              <li className="text-white fs-13 footer-item-lista">
                <a href="planos">Planos</a>
              </li>
              {/* <li className="text-white fs-13 footer-item-lista"><a href="#">Enviar Feedback</a></li> */}
            </ul>
          </div>

          <div class="col-6 col-md-2 mb-3">
            <ul className="list-unstyled   flex-column">
              <li className="text-white fw-bold fs-14 footer-item-lista" >Suporte</li>
              <li className="text-white fs-13 footer-item-lista">
                <a
                 href={process.env.REACT_APP_WHATSAPP} 
                  target="_blank"
                >
                  Como Começar
                </a>
              </li>
              {/* <li className="text-white fs-13 footer-item-lista"><a href="#">Documentação</a></li> */}
              {/* <li className="text-white fs-13 footer-item-lista"><a href="#">Segurança</a></li> */}
              <li className="text-white fs-13 footer-item-lista">
                <a href="#">FAQ</a>
              </li>
              {/* <li className="text-white fs-13 footer-item-lista"><a href="#">Enviar Feedback</a></li> */}
            </ul>
          </div>

          <div class="col-6 col-md-2 mb-3 " style={{ marginLeft: "2.5%" }}>
            <ul className="list-unstyled   flex-column">
              <li className="text-white fw-bold fs-14 footer-item-lista ">Companhia</li>
              <li className="text-white fs-13 footer-item-lista">
                <a href="contatos">Sobre nós</a>
              </li>
      
              <li className="text-white fs-13 footer-item-lista">
        <a  href={process.env.REACT_APP_WHATSAPP} target="_blank"   >               
             
             Contate-nos  
           </a>
          
           </li> 

















            </ul>
          </div>

          <div className="col-12 col-sm-3 col-dm-3 col-lg-3 w-auto p-4 m-rl-40">
            <ul className="list-unstyled wi-100">
              <li className="lista-footer-icon pb-3">
                <img src={require("../../img/favicon.png")} alt="yamacorp" />
                &nbsp;&nbsp;
                <a
                 href={process.env.REACT_APP_WHATSAPP} 
                  target={"_blank"}
                  className="btn btn-fale--conosco"
                >
                  <span style={{ fontWeight: "bold", fontSize: "19px" }}>
                    <SiWhatsapp style={{ fontSize: "27px" }} />
                    &nbsp;&nbsp;Fale conosco
                  </span>{" "}
                  &nbsp;
                </a>
              </li>

              <li className="text-white fs-13 footer-item-lista redes-sociais">
                {/* <a href="#">
                  <SiDiscord />
                </a> */}
                <a
                  href="https://www.facebook.com/profile.php?id=100081346864288"
                  target="blank"
                >
                  <SiFacebook />
                </a>
                {/* <a href="https://twitter.com">
                  <SiTwitter /> 
                </a>
                <a href="https://linkedin.com">
                  <SiLinkedin />
                </a> */}
                <a
                  href="https://www.instagram.com/yamacorp_sistema/"
                  target="blank"
                >
                  <SiInstagram />
                </a>
                <a
                  href={process.env.REACT_APP_WHATSAPP} 
                  target="_blank"
                >
                  <SiWhatsapp />
                </a>
              </li>
              <li className="text-white fs-13 footer-item-lista">
                <div className="d-flex">
                  Copyright &copy; {today.getFullYear()}
                  &nbsp;Yamcol Sistemas de Serviços de Informática Eireli.
                  <br />
                  Todos os Direitos Reservados
                </div>
              </li>
              <li className="text-white fs-13 termos-link">
                <a href="#">Termos de privacidade</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
// exports.default = Footer;
