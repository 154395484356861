import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  FaBars,
  FaAddressBook,
  FaClone,
  FaCalendarAlt,
  FaChartBar,
  FaCopy,
  FaHome,
  FaLuggageCart,
  FaAccessibleIcon,
  FaAppleAlt,
  FaHeadSideCough,
  FaHeadphones,
  FaAdversal,
  FaReadme,
  FaAnkh,
  FaCottonBureau,
  FaSign,
  FaSignal,
  FaXingSquare,
  FaFax,
  FaRegAddressCard,
} from "react-icons/fa";
// import logo from '../../img/favicon.png'





export function Header({ menuAtivo }) {




  
  //   useEffect(() => {
  //     async function loadPage(){
  //         setTimeout(function () {
  //           test();
  //         });
  //     }
  //     loadPage();
  // }, []);

  // function test() {
  //   var tabsNewAnim = $('#navbarSupportedContent');
  //   var selectorNewAnim = $('#navbarSupportedContent').find('li').length;
  //   var activeItemNewAnim = tabsNewAnim.find('.active');
  //   var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
  //   var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
  //   var itemPosNewAnimTop = activeItemNewAnim.position();
  //   var itemPosNewAnimLeft = activeItemNewAnim.position();
  //   $(".hori-selector").css({
  //     "top": itemPosNewAnimTop.top + "px",
  //     "left": itemPosNewAnimLeft.left + "px",
  //     "height": activeWidthNewAnimHeight + "px",
  //     "width": activeWidthNewAnimWidth + "px"
  //   });
  //   $("#navbarSupportedContent").on("click", "li", function (e) {
  //     $('#navbarSupportedContent ul li').removeClass("active");
  //     $(this).addClass('active');
  //     var activeWidthNewAnimHeight = $(this).innerHeight();
  //     var activeWidthNewAnimWidth = $(this).innerWidth();
  //     var itemPosNewAnimTop = $(this).position();
  //     var itemPosNewAnimLeft = $(this).position();
  //     $(".hori-selector").css({
  //       "top": itemPosNewAnimTop.top + "px",
  //       "left": itemPosNewAnimLeft.left + "px",
  //       "height": activeWidthNewAnimHeight + "px",
  //       "width": activeWidthNewAnimWidth + "px"
  //     });

  //   });
  // }
  // $(window).on('resize', function () {
  //   setTimeout(function () { test(); }, 500);
  // });

  // $(".navbar-toggler").on('click', function () {
  //   $(".navbar-collapse").slideToggle(300);
  //   setTimeout(function () { test(); });
  // })

  // --------------add active class-on another-page move----------
  function handleClick(e) {
    e.preventDefault();
    $(".navbar-collapse").slideToggle();
  }

  //  $(function(){
  //     $(".button-collapse").sideNav();
  //  });


  return (
    
    <nav className="navbar sticky-top navbar-expand-lg bg-light nav-pills nav-justified navbar-dark bg-dark ">
      <div class="container-fluid">
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
            fontSize: 25,
            color: "gray",
            marginLeft: "5%",
          }}
        >
          <img
            className={menuAtivo}
            src={require("../../img/favicon.png")}
            alt="yamcol"
          />
          &nbsp; YAMA CORP
        </Link>
        
       
      


        <button
          onClick={handleClick}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ marginRight: "0 auto", width: "1x" }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>


        {/* <a id="btn" href="/Login" target="_blank"
            style={{padding: "10px 40px 10px 40px", backgroundColor: '#FFD700', borderRadius: '12px', textDecoration: 'none', marginLeft: '45px'    }}
             
             >Acesse o sistema
            </a> */}

        {/* style={{padding: '0px 70px 0px 70px', marginRight: '500px'}} */}
        
       
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
           
           
   

       <li>
        <a  href={process.env.REACT_APP_ACESSO_AO_SISTEMA} target="_blank">
           
           <button style={{padding: '5px 75px 5px 75px',  marginLeft: '40px', marginTop: '5px'}}
             
             type="submit"
             class="btn btn-outline-warning"
             
           >
                   
             <h6 style={{ textAlign: "center", marginTop: "5px" }}>
               Acesso ao sistema
             </h6>
           </button>
          
           </a>
          
           </li> 

          <ul

          
            className="navbar-nav me-auto mb-2 mb-lg-0"
            style={{ width: "45%", marginLeft: "20%" }}
          >
            
               


            <li style={{ width: "75%", marginLeft: "-5%" }}
              className={menuAtivo === "home" ? "nav-item active" : "nav-item"}
            >
              <Link to="/" className="nav-link">
                <FaHome /> Início
              </Link>
            </li>
            <li style={{ width: "75%", marginLeft: "-5%" }}
              className={
                menuAtivo === "contatos" ? "nav-item active" : "nav-item"
              }
            >
              <Link
                to={menuAtivo === "contatos" ? "" : "/contatos"}
                className="nav-link"
              >
                <FaAddressBook /> Sobre
              </Link>
            </li>
            <li style={{ width: "75%", marginLeft: "-5%" }}
              className={
                menuAtivo === "Planos" ? "nav-item active" : "nav-item"
              }
            >
              <Link
                to={menuAtivo === "planos" ? "" : "/planos"}
                className="nav-link"
              >
                <FaClone /> Planos
              </Link>
            </li>

            {/* <li
              className={
                menuAtivo === "link" ? "nav-item active" : "nav-item"
              }
            >
              <Link
                to={menuAtivo === "link" ? "" : "/link"}
                className="nav-link"
              >
                <FaClone /> Login
              </Link>
            </li> */}
            {/* <li className={menuAtivo === 'Cadastro' ? "nav-item active": 'nav-item'}>
            <Link to={menuAtivo === 'Cadastro' ? '' : '/cadastro'} className='nav-link'><FaFax /> Cadastro</Link>
                      </li>
          <li className={menuAtivo === 'Teste' ? "nav-item active": 'nav-item'}>
            <Link to={menuAtivo === 'Teste' ? '' : '/teste'} className='nav-link'><FaRegAddressCard /> Teste</Link>
                      </li> */}
                     
                



          </ul>

          
        </div>
      </div>
    </nav>



      








    
  );
}

//  export default Header
