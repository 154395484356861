import React from 'react'
import { Header } from '../../components/Header';
import { AnimationOnScroll } from 'react-animation-on-scroll';
export function Contatos() {


  return (
    <>
      <Header menuAtivo='contatos'></Header>

      <div className="container-fluid backHome p-0">
        <div className=" " style={{ width: '100%' }}>
          <img src={require('../../img/capanext2.png')} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="container-fluid pb-5 pt-5">
        <div className="col-12">
          <div className="row justify-content-around">
          <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/controle-por-biometria.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Controle de Ponto</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Controle de Vale Transporte, Ponto Biometrico e Face ID com Geolocalização!
              </div>
            </div>
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/Intranet.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Intranet</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Sistema de troca de mensagens instantaneas entre o gestor e seus colaboradores.
              </div>
            </div>
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/Gestão-de-financeira.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Frente de Loja Completo</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                PDV completo com sistema de promoções, gincanas, cadastro de clientes, e interação direta com a gestão.
              </div>
            </div>          
          </div>

          

          <div className="row justify-content-around mt">
          <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/conciliação-banco-cartão.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Conciliação Bancária e Cartão</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Basta integrar o seu extrato bancário para o Yama Corp e tudo será conferido automaticamente.
              </div>
            </div>

            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/gamificação.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gameficação</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Sistema de gincanas, recompensas, bonificações, cupons de desconto , entre outros!
              </div>
            </div>
            
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/nuvem1.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Integração com Sistemas de Franquias</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Com o Yama Corp você pode controlar sua franquia de lojas através de uma retaguarda eficiente.
              </div>
            </div>           
          </div>

          <div className="row justify-content-around mt-5">
            {/* <div className="col-sm-4 col-md-2 col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/gestão-de-compras.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gestão de Compras</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div> */}
            {/* <div className="col-sm-4 col-md-2 col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/Gestão-multilojas.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gestão Multilojas</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div> */}
            {/* <div className="col-sm-4 col-md-2 col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/Gestão-multilojas.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gestão Multilojas</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </div> */}
          </div>
        </div>
        <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 bg-gray pt-5 pb-5">
            <h3 className="yama-titles text-center">
              Nosso diferencial
            </h3>
            <div className="row mt-1 justify-content-around align-items-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                <div className="yama-sobre-desc fs-18">
                  <p className="text-justify">
                    O Grupo Yamcol está no mercado desde 1970 no ramo do varejo com a gestão seguindo os princípios da cultura japonesa.<br />
                    </p>  <p className="text-justify">
                    Nesse primeiro momento, trabalhamos com lojas multimarcas, e no decorrer dos anos , grupo foi crescendo e adquirindo várias franquias, foi nesse momento que surgiu a necessidade de um sistema para fazer o gerenciamento de várias lojas/franquias em um único lugar, dado que às franquias utilizam sistemas diferentes. Então arregaçamos as mangas e desenvolvemos o nosso próprio sistema (Yama Corp). Atualmente trabalhamos com as marcas Taco, Touch, Kattleya, Yamcol Modas e Victor Hugo, totalizando 40 lojas, o sistema vem se comportando com eficácia.<br />
                    </p>  <p className="text-justify">
                    Como multilojistas, sentimos as dores para alcançar um gerenciamento completo e eficaz, visando compartilhar da nossa perícia na solução dos nossos problemas nestes 50 anos de mercado, oferecemos uma experiência completa do nosso sistema, desde o frente de loja, controle de ponto e a gestão de pessoas, visando desenvolver o varejo de maneira eficaz e altamente automatizada por um preço único.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                < div className="wi-100">
                  <img className="wi-100" src={require('../../img/iconst.png')} alt="yama-background" />
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div className="container-fluid pt-5 pb-5 ">
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <h3 className="yama-titles text-center">
              Sobre a YamaCorp
            </h3>
          </div>
          <div className="row mt-3 justify-content-around align-items-center">
            <div className="col-12 col-sm-4 col-md-4 col-lg-4">
              < div className="wi-100">
                <img className="wi-100" src={require('../../img/yama.1.png')} alt="yama-background" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-1">
              <div className="yama-sobre-desc fs-18">
             
              <p className="text-justify d-flex p-2 bd-highlight " >
                  Somos um sistema ERP completo, desde o frente de loja, passando pelos relatórios
                  gerenciais, gestão de pessoas, vendas, estoque, financeiro, contábil, controle de vale transporte, contas a receber,
                  conciliação bancária, cartão de crédito, CRM, ponto biométrico e várias outras funcionalidades que só o Yama Corp pode oferecer.<br />

                  Uma experiência completa em nuvem para você cuidar do seu negócio de qualquer lugar, da melhor maneira, de forma prática e segura.

               
                </p>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>
      <div >
      <div className="container" style={{marginBottom: '-150px'}}>
          <div className="col-sm-12 col-md-12 col-lg-12 col-12 d-flex align-items-center text-center mt-5 flex-column ">
          <h2 className="" style={{ width: 'auto', marginTop: '-70px', height: '50px' }}>
              Parceiros
            </h2>
          </div>
          <div className="row pb-5" style={{marginLeft: '2.5%'}}>
            <div className="row mt-2 justify-content-around">
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
              
                <img className="wi-100" src={require('../../img/kat-.png')} alt="kattleya" />
              </div>
              <div className="col-12 col-md-1 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/taco-.png')} alt="taco" />
              </div>
              <div className="col-12 col-md-1 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/touch-.png')} alt="touch" />
              </div>
              <div className="col-12 col-md-1 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/vh-.png')} alt="touch" />
              </div>



            </div>
            <div className="row mt-1 justify-content-around pt-5">
            
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/melissa.png')} alt="touch" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/impla.png')} alt="touch" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/yamcolmodas.png')} alt="touch" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
              <img className='wi-100' src={require('../../img/theluxo2.png')} alt="touch" style={{marginTop: '-10px', height: '55%', marginBottom: '95px' }}  />
              </div>
              
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

// export default Contatos