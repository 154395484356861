import React, { useState } from "react";
import emailjs from 'emailjs-com';

export function ButtonEmail() {
  const [Iempresa, setEmpresa] = useState("");
  const [Inome, setNome] = useState("");
  const [Iemail, setEmail] = useState("");
  const [Imensagem, setMensagem] = useState("");
  
  const [Icontato, setPhone] = useState("");


    const maskPhone = (value) => {
      return value
         
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})(\d+?)$/, "$1");
    };

    
function enviar(){
 


  const data = { empresa: Iempresa, nome: Inome, email: Iemail, contato: Icontato, mensagem: Imensagem};


  fetch(process.env.REACT_APP_CONTATO_CLIENTE + "contato/clientes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
  }); 
}
  function validar() {
    

   // var Iempresa = document.getElementById('Inputempresa').value;
   // var Inome = document.getElementById('Inputnome').value;
   // var Iemail = document.getElementById('Inputemail').value;
    //var Icontato = document.getElementById('Inputcontato').value;
   // var Imensagem = document.getElementById('Inputmensagem').value;
     
     if (Iempresa === "") {        
      
      alert('Campo empresa é obrigatório '); 
  } else if (Inome === "") {        
      
    alert('Campo nome é obrigatório '); 
}
else if (Iemail === "") {        
      
  alert('Campo email é obrigatório '); 
} 
else if(Icontato.length <= 14  )
{         
  
  alert('Campo contato é obrigatório: (99) 99999-9999 ');
  Icontato.addEventListener('invalid', function () {
    Icontato.setCustomValidity('(00) 00000-0000');        
         }, false);
 } 
else if (Imensagem === "") {        
      
  alert('Campo mensagem é obrigatório '); 
} 

   else {
  enviar()
   }
}

      

    

  

  //     var contato = document.getElementById('contato');

  //     contato.addEventListener('invalid', function () {
  //         contato.setCustomValidity('(00)00000-0000');
  //     }, false);

  //     contato.addEventListener('change', function () {
  //        contato.setCustomValidity('');
  //    }, false);

  var body = document.getElementsByTagName("body")[0];
  const [aberto, setAberto] = useState(true);
  const showDropdown = () => {
    setAberto(!aberto);
    body.classList.add("actionsBoxOpen");
    var btn = document.getElementById("actionBtn");
    btn.classList.remove("wobble-hor-bottom");
  };

  const closeDropdown = (event) => {
    setAberto(!aberto);
    body.classList.remove("actionsBoxOpen");
     
  };

  // body.addEventListener('click', function(){
  //     if(body.classList.contains('actionsBoxOpen'))
  //          body.classList.remove('actionsBoxOpen')
  //  })

   function sendEmail(e) {
          e.preventDefault();
          emailjs.sendForm(
         "service_34hqher",
         "template_trw9aa6",
          e.target,
         "WIQxBHOMqZAwtK4qY"
       )

       .then(
         (result) => {
        

           alert(
             "Obrigado sr(a), os seus dados foram encaminhados com sucesso, 👍 aguarde nosso contato"
           );
         },
         (error) => {
           alert(error.message);
         }
       );
     e.target.reset();
     setAberto(!aberto);
     body.classList.remove("actionsBoxOpen");
   }
  return (
    <>
      {/* <div className="col-xl-2 col-sm-1 col-md-1"> */}
      <div
        id="actionBtn"
        onClick={showDropdown}
        className="action-button wobble-hor-bottom "
        style={{ width: "85px", height: "85px", marginRight: "40px"}}
      ></div>

      <div
        className="action-button-content "
        
        id="popupEmail"
        style={{
          width: "auto",
          borderRadius: "19px 19px 20px 20px",
          marginLeft: "4%"
        }}
      >
        <h6 style={{ textAlign: "center", marginTop: "5%" }}>
          <strong> Yama Fone - Fale Conosco</strong>{" "}
        </h6>
        <div
          className="action-button-content-inner"
          id="div_contato_principal"
          style={{ marginTop: "-45px" }}
        >
          <div className="content-btn">
            <div className="row">
              <div className="">
                <span className="title-button">
                  <p
                    style={{
                      fontSize: 14,
                      textAlign: "center",
                      color: "gray",
                      fontFamily: "serif ",
                      margin: "0px 0px 10px 0px",
                      padding: "0px 0px 0px 0px",
                    }}
                  ></p>
                </span>
              </div>
            </div>
            {/* onSubmit={sendEmail} */}
           <form className="needs-validation" onSubmit={sendEmail}   id="formulario" name="formulario"  novalidate>
            <div className="row  mt-1">
                <div className="col-3">
                  <label
                    htmlFor="Inputempresa"
                    style={{ float: "left", fontSize: "10px" }}
                  >
                    <strong>Empresa:</strong>
                  </label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    id="Inputempresa"
                    name="Inputempresa"
                    placeholder="Sua empresa"
                    required
                    style={{ fontSize: "10px" }}
                    value={Iempresa}
                    onChange={(e) => setEmpresa(e.target.value)}
                  />
                </div>
              </div>

              <div className="row  mt-1">
                <div className="col-3">
                  <label
                    htmlFor="Inputnome"
                    style={{ float: "left", fontSize: "10px" }}                  >
                    <strong>Nome:</strong>
                  </label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    name="Inputnome"
                    id="Inputnome"
                    placeholder="Digite seu nome.."
                    required
                    style={{ fontSize: "10px" }}
                    value={Inome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
              </div>


              <div className="row  mt-1">
                <div className="col-3">
                  <label
                    htmlFor="Inputemail"
                    style={{ float: "left", fontSize: "10px" }}
                  >
                    <strong>Email:</strong>
                  </label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    id="Inputemail"
                    name="Inputemail"
                    placeholder="exemplo@exemplo.com"
                    required
                    style={{ fontSize: "10px" }}
                    value={Iemail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="row  mt-1">
                <div className="col-3">
                  <label
                    htmlFor="Inputcontato"
                    style={{ float: "left", fontSize: "10px" }}                  >
                    <strong>Contato:</strong>
                  </label>
                </div>
                <div className="col">
                  <input
                    
                    type="text"
                    id="Inputcontato"
                    pattern="\(\d{2}\) \d{5}-\d{4}"
                    className="form-control "
                    name="Inputcontato"
                    placeholder="(99) 99999-9999"
                    alt="(91) 99999-9999"
                    required
                    style={{ fontSize: "10px" }}
                    value={Icontato}
                    onChange={(e) => setPhone(maskPhone(e.target.value))}
                  />
                </div>
                <div id="aviso"></div>
              </div>

              <div className="row  mt-1">
                <div className="col-3">
                  <label
                    htmlFor="Inputmensagem"
                    style={{ float: "left", fontSize: "10px" }}
                  >
                    <strong>Mensagem:</strong>
                  </label>
                </div>
                <div className="col">
                  <textarea
                    class="form-control"
                    id="Inputmensagem"
                    name="Inputmensagem"
                    rows="6"
                    cols="14"
                    placeholder="Conte-nos do que a sua empresa precisa, temos a solução para a sua empresa!!! "
                    style={{ fontSize: "10px" , resize: "none"}}
                    required
                    value={Imensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                    >
                    
                  </textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <button
                    type="submit"
                    id="btnEnviar"
                    onClick={validar}
                    className="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    name="btnEnviar"
                  >
                    Enviar
                  </button>
                </div>
                
               

                <div className="col-9">
                  <button
                    type="button"
                    onClick={closeDropdown}
                    className="btn btn-danger"
                  >
                    Fechar
                  </button>
                </div>
              </div>

              <input type="hidden" value={"YAMA CORP - SITE"} name="subject" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

// export default ButtonEmail
