import React, { useState, useEffect } from 'react'
import '../../css/app.css'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Header } from '../../components/Header/index.js';
import { AnimationOnScroll } from 'react-animation-on-scroll';


export function Home() {

  // useEffect(() => {
  //   newTypeWritter();
  // }, [])

  const Accordion = ({ title, content, id }) => {
    const [isActive, setIsActive] = useState(false);
    var len = (id + Math.random() * 14);

    return (

      <div key={len + 1}>
        <a href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="pt-2 link-home-desc yama-colo-red d-flex" key={len + 2} onClick={() => setIsActive(!isActive)}>
          <div key={len + 3}>{isActive ? '-' : '+'}</div> &nbsp;
          <div key={len + 4}>{title}</div>

        </a>
        {isActive && <div className="text-muted text-justify" key={len + 5}>{content}</div>}
      </div>
    );
  };

  const accordionData = [{
    title: 'Agende uma Apresentação!',
    content: ''
  }]


  const Accordion2 = ({ title, content, id }) => {
    const [isActive, setIsActive] = useState(false);
    var len = (id + Math.random() * 14);

    return (

      <div key={len + 1}>
        <a href={process.env.REACT_APP_WHATSAPP}  target="_blank" className="pt-2 link-home-desc yama-colo-red d-flex" key={len + 2} onClick={() => setIsActive(!isActive)}>
          <div key={len + 3}>{isActive ? '-' : '+'}</div> &nbsp;
          <div key={len + 4}>{title}</div>

        </a>
        {isActive && <div className="text-muted text-justify" key={len + 5}>{content}</div>}
      </div>
    );
  };

  const accordionData2 = [{
    title: 'Saiba Mais!',
    content: ''
  }]
  
  return (
    <>
      <Header menuAtivo='home'></Header>
      <div className="container-fluid backHome p-0">
        <div  style={{ width: '100%' }}>
          <img  src={require('../../img/capa1.png')} alt="" className="img-fluid"  />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12 text-center p-20">
    
            <h2>
              Um sistema ERP completo com modelo de gestão replicável e 100% Web

            </h2>
            <h6 className="text-muted mb- yama-desc-text">
              Gerencie sua empresa de qualquer lugar, praticidade na palma da sua mão!
            </h6>
          </div>

          <div className="row justify-content-around">

            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/controle-por-biometria.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Biometria</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Controle de Vale Transporte, Ponto Biometrico e Face ID com Geolocalização!
              </div>
            </div>
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/financeira.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gestão Financeira</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Contabilidade, Estoque Contábil, Fluxo de Caixa, DRE, Análise de Pagamentos, Análise de Recebimentos, e Folha de Pagamento.
              </div>
            </div>
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/gestão-de-estoque.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gestão de Estoque</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Controle a entrada e saída das suas mercadorias, de maneira rápida e prática.
              </div>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/gestão-de-compras.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gestão de Compras</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                BI de gestão de compras, sugestão de cota de compras por produtos e fornecedores.
              </div>
            </div>
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/gestão-de-patrimônio.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Controle de Patrimônio</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Ferramenta de suma importância permitindo catalogar, gerar códigos e fazer a gestão de
                todos os equipamentos da sua empresa.
              </div>
            </div>
            <div className="col-sm-4 col-md- col-lg-3 col-12 p-20">
              <div className="logo-card-yama d-flex justify-content-center">
                <img src={require('../../img/gestão-de-vendas.png')} alt="biometria" />&nbsp;
                <h5 className='text-black'>Gerênciamento de Vendas</h5>
              </div>
              <div className="card-yama-content text-muted text-center mt-4">
                Prêmios, Promoções, Gincanas, Cumpons de Desconto, Gameficações para o Time de Vendas, Vitrine, Sorteio e Dashboard.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid  bg-gray a">
        <AnimationOnScroll animateIn="animate__fadeInUp"
          animateOut="animate__fadeOutUp"
          duration={1.7}
          animateOnce={true}
          delay={0}>
          <div className="row mt-2 container-fluid justify-content-center">
            <div className="col-sm-12 col-md-1 col-lg-12 col-12 d-flex align-items-center text-center mt-5 flex-column ">
              <h2>
                Otimizamos seu trabalho
              </h2>
              <h6 className="text-black mb-5 yama-desc-text ln-30">
                A melhor expêriencia web para o controle da sua loja.
                <br />
                Integração direta entre o Backoffice e o Frente de Loja.
              </h6>
              <a href={process.env.REACT_APP_WHATSAPP} target="_blank" className="btn btn-outline-primary yama-button" style={{ marginTop: '-30px' }}>
                Fale com o consultor!<FaLongArrowAltRight />📲 &nbsp;
              </a>
            </div>
            </div>
            {/* col-md-6 col-sm-6 col-lg-6 col-12 d-flex justify-content-center mt- mb-5 */}
            
                 {/*                 */}
              {/* <div className="row">                                
            <div className="col-md- col-sm- col-lg- col- d-flex justify-content-center mt- mb-5">              
                          FRENTE D EOLS
            </div>        
            
            

            <div className="col-md-6 col-sm-6 col-lg-6 col-2 d-flex justify-content-center mt- mb-5">              
              <img src={require('../../img/yamacorp-background.JPG')} className='yamacorp-background' />
            </div>    
                
            <div className="col-md- col-sm- col-lg- col- d-flex justify-content-center mt- mb-5">              
                          Backoffice
            </div>  
            
               <div className="col-md-6 col-sm-6 col-lg-6 col-2 d-flex justify-content-center mt- mb-5">           
              <img src={require('../../img/pdv1.png')} className='yamacorp-background' />
            </div>
          </div> */}




<div className=" d-md-flex flex-md-equal w-100 my-md-2 ps-md-3  justify-content-center" >
    <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
      <div className="my- p-">
        <h4 className="display-5">Backoffice</h4>
        {/* <p className="lead">And an even wittier subheading.</p> */}
      </div>
      <div className="bg-body shadow-sm mx-auto" style={{width: '100%', height:'85%'}}>
      <img   src={require('../../img/yamacorp-background.JPG')} className='yamacorp-background' style={{width:'100%', height: '100%', borderRadius: '9px 9px 0 0'}}  />
      
      </div>
    </div>
    <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
      <div className="my- py-">
        <h4 className="display-5">Frente de Loja</h4>
        {/* <p className="lead">And an even wittier subheading.</p> */}
      </div>
      <div className="bg-body shadow-sm mx-auto" >
      <img src={require('../../img/pdv1.png')} className='yamacorp-background' style={{ borderRadius: '9px 9px 0 0'}}  />
      </div>
    </div>
  </div>

        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn"
          animateOut="animate__fadeIn"
          duration={1.7}
          animateOnce={true}
          delay={0}>
          <div className="row mt- align-items-around justify-content-center  " style={{marginLeft: '0%'}}>
            <div className="col-12 col-md-4 col-sm-4 col-lg-4" style={{ paddingTop: '7rem' }}>
              <h2>Aplicativo YamaCorp Biometria</h2><br />
              <span className='text-muted'>Aplicativo intuitivo para você realizar o registro da sua jornada de trabalho e de seus colaboradores.</span>
              <br />
              
              <div className="buttons d-flex align-items-center mt-5" >
                <a href="">
                  <img src={require('../../img/google-play-badge.png')} style={{ width: '150px' }} alt="" />
                </a>
                <a href="">
                  <img src={require('../../img/disponivel-na-app-store-botao-2.png')} style={{ width: '150px' }} alt="" />
                </a>
              </div>

            </div>
            <div className="col-12 col-md-6 col-sm-6 col-lg-6" >
              <img src={require('../../img/cel1.png')} className="wi-100" alt="" />
            </div>
          </div>
        </AnimationOnScroll>

      </div>
      <div className="container ptr-2 " style={{ paddingTop: '2rem !important' }}>
        <div className="row">
          <AnimationOnScroll animateIn="animate__fadeInBottomLeft"
            animateOut="animate__fadeOut"
            duration={1.5}
            animateOnce={true}
            delay={0}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-12 d-flex align-items-center text-center mt-5 flex-column">
                <h2>
                  Excelentes recursos para sua loja ter sucesso!
                </h2>
                {/* <span className="home-desc-sec text-muted text-left fs-17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis lacus id lacus malesuada maximus.
                Pellentesque scelerisque augue quis purus fringilla. at euismod felis tempus. Nullam tempor consectetur vehicula.
                Integer tristique neque eu suscipit ultrices.
              </span> */}
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInBottomRight"
            animateOut="animate__fadeOut"
            duration={1.5}
            animateOnce={true}
            delay={0}>
            <div className="row mb-5 mt-5 flex-wrap table-responsive table-responsive-lg table-responsive-md table-responsive-sm">
              <div className="col-12 col-sm-12 col-md-12 col-xl-12 d-flex flex-wrap ">
                <div className="col-12 col-sm-6 col-lg-6 col-md-6 mb-4 mt-4 d-flex flex-wrap ">
                  <div className="col-12 col-sm-10 col-lg-4 col-md-3 ">
                    <div className="img-home-desc ">
                      <img src={require('../../img/3celulares.png')} alt="" style={{ width: 'auto', marginTop: '-18px', marginBottom: '-25px', height: '350px' }} />
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-10 col-lg-4 col-md-3"> 
                    <div className="img-home-desc">
                      <img src={require('../../img/biometria1.png')} alt="" style={{ width: 'auto', marginTop: '-18px', marginBottom: '-25px', height: '350px' }} />
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-sm-10 col-lg-4 col-md-3">
                    <div className="img-home-desc">
                      <img src={require('../../img/cortado..png')} alt="" style={{ width: 'auto', marginTop: '-18px', marginBottom: '-25px', height: '350px' }} />
                    </div>
                  </div> */}
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-md-6 mb-4 mt-4 d-flex flex-wrap">
                  <div className="home-desc-text ">
                    <h4 className="yama-titles">
                      Controle de Ponto
                    </h4>
                    <span className="home-desc-sec text-muted">
                      Aplicativo próprio para você efetuar o seu Controle de Vale Transporte, Ponto Biometrico com Face ID e Geolocalização!
                    </span><br /><br />

                    <div className="accordion">
                      {accordionData.map(({ title, content }) => (

                        <Accordion key={content.length} title={title} content={content} id={content.length} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInBottomLeft"
            animateOut="animate__fadeOut"
            duration={1.5}
            animateOnce={true}
            delay={0}>
            <div className="row mb-5 mt-5" style={{marginLeft: '2.5%'}}>
              <div className="col-12 col-sm-12 col-md-12 col-xl-12 d-flex flex-wrap">
                <div className="col-12 col-sm-6 col-lg-6 col-md-6 mb-4">
                  <div className="home-desc-text">
                    <h4 className="yama-titles">
                      Envio e Recebimento de Mensagens
                    </h4>
                    <span className="home-desc-sec text-muted">
                      Sistema INTRANET com envio e recebimento de mensagens dentro da plataforma de gestão,
                      permitindo uma comunicação rápida e fácil entre seus colaboradores.
                    </span><br /><br />
                    <div className="accordion">
                      {accordionData2.map(({ title, content }) => (
                        <Accordion2 key={content.length} title={title} content={content} id={content.length} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-md-6 mt-4">
                  <div className="img-home-desc">
                    <img src={require('../../img/imagem 24.jpg')} alt="" />
                  </div>
                </div>
                {/* <div className="col-12 col-sm-3 col-lg-3 col-md-3 mt-4">
                <div className="img-home-desc">
                  <img src={require('../../img/MENSAGEM2.jpg')} alt="" style={{ width: 'auto', marginTop: '-18px', marginBottom: '-25px', height:'250px'  }} />
                </div>
              </div> */}
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInBottomRight"
            animateOut="animate__fadeOut"
            duration={1.5}
            animateOnce={true}
            delay={0}>
            <div className="row mt-5" style={{marginLeft: '2.5%'}}>
              <div className="col-12 col-sm-12 col-md-12 col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                <div className="col-12 col-sm-6 col-lg-6 col-md-6">
                  <div className="img-home-desc">
                    <img src={require('../../img/gestão-de-pessoas-site.jpg')} alt="" style={{ width: '100%' }} />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-4 mb-4">
                  <div className="home-desc-text" >
                    <h4 className="yama-titles">
                      Gestão de pessoas
                    </h4>
                    <span className="home-desc-sec text-muted">
                      Controle de vale transporte, Folgas, Feriados, Faltas,
                      Advertências, Suspenções, Exames Laborais, Uniformes e Folha de Pagamento.

                    </span>
                    <br />
                    <br />

                    <div className="accordion">
                      {accordionData.map(({ title, content }) => (

                        <Accordion key={content.length} title={title} content={content} id={content.length} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInBottomLeft"
            animateOut="animate__fadeOut"
            duration={1.5}
            animateOnce={true}
            delay={0}>
            <div className="row mb-5 mt-5" style={{marginLeft: '2.5%'}}>
              <div className="col-12 col-sm-12 col-md-12 col-xl-12 d-flex flex-wrap align-items-center">
                <div className="col-12 col-sm-6 col-lg-6 col-md-6 mb-5">
                  <div className="home-desc-text">
                    <h4 className="yama-titles">
                      Dashboard
                    </h4>

                    <span className="home-desc-sec text-muted">
                      Um painel visual que cotém informações e indicadores da empresa,
                       apresentando números importantes que possibilitam a criação de estratégias para o alcance dos objetivos organizacionais.
                    </span>
                    <br />
                    <br />
                    <div className="accordion">
                      {accordionData.map(({ title, content }) => (

                        <Accordion key={content.length} title={title} content={content} id={content.length} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6 col-md-6 mt-4">
                  <div className="img-home-desc">
                    <img src={require('../../img/dashboard.png')} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

        </div>
      </div>
      <div >
      
        <div className="container" style={{marginLeft: '2.5%', marginBottom: '-130px'}}>
          <div className="col-sm-12 col-md-12 col-lg-12 col-12 d-flex align-items-center text-center mt-5 flex-column ">
            <h2 className="" style={{ width: 'auto', marginTop: '-70px', height: '50px' }}>
              Parceiros
            </h2>
          </div>
          <div className="row pb-5">
            <div className="row mt-2 justify-content-around">
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className="wi-100" src={require('../../img/kat-.png')} alt="kattleya" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/taco-.png')} alt="taco" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/touch-.png')} alt="touch" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                <img className='wi-100' src={require('../../img/vh-.png')} alt="vitor-hugo" />
              </div>
            </div>
            <div className="row mt-2 justify-content-around pt-5">

            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
              <img className='wi-100' src={require('../../img/melissa.png')} alt="melissa" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
              <img className='wi-100' src={require('../../img/impla.png')} alt="impla" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
              <img className='wi-100' src={require('../../img/yamcolmodas.png')} alt="yamcolmodas" />
              </div>
              <div className="col-12 col-md-2 col-sm-2 col-lg-2">
              <img className='wi-100' src={require('../../img/theluxo2.png')} alt="touch" style={{marginTop: '-15px', height: '55%' }}  />
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
 export default Home